import React from 'react'
import Loading from './Loading'
import Title from './Title'
import { Container, Row, Col } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

function Packagetable({data, classText, styleTwo, styleThree, styleFour}) {

    let publicUrl = process.env.PUBLIC_URL + '/'
    if(!data) {
        return <Loading />
    }
    return (

        <section className={`${classText} pricing`}>
            <Container>
                <Row>
                    <Col>
                        {/* Section Title */}
                        <Title title={data.title} subtitle={data.desc} classText={`${styleTwo ? 'title-shape title-shape-sky-blue text-center' : styleThree ? 'text-white title-shape title-shape-style-two text-center' : styleFour ? 'title-shape text-center' : 'text-white title-shape title-shape-style-two text-center'}`} />
                        {/* End Section Title */}
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {
                        data.included?.map((item, index) => {
                            let active = index === 1 ? "active" : '';
                            return (
                                <div key={index} className="col-lg-12 col-md-12">
                                    <div className="packagetable">
                                    <table>
                                        <tr>
                                        <th></th>
                                        <th>Basic</th>
                                        <th>Pro</th>
                                        
                                        </tr>
                                        <tr> 
                                        <th>24/7 Support</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Unlimited Revisions</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Unlimited Maintenance</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Unlimited Hosting</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>
                                        

                                        <tr>
                                        <th>Client Portal for Customization</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Loyalty Program Eligibility</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Mobile Compatibility</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>
                                        

                                        <tr>
                                        <th>Custom Built Website</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Additional Webpages</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Online Reservation</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Online Appt Booking</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Online Ordering</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Instant Quotes</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Inquiry Forms</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Gift Cards</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Basic SEO Setup</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Embedded Social Media</th>
                                        <td><center><FaCheck /></center></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>Annual Checkups</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>

                                        <tr>
                                        <th>New Upcoming Services</th>
                                        <td></td>
                                        <td><center><FaCheck /></center></td>
                                        
                                        </tr>
                                        
                                    </table>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Row>
            </Container>
        </section>
    );
}

export default Packagetable;

Packagetable.defaultProps = {
    classText: 'gradient-bg pt-120 pb-90',
    styleTwo: false,
    styleThree: false
}