import React, { useContext, useEffect } from 'react'
import { DataContext } from '../context'
import Footer from '../components/Footer'
import Map from '../components/Map'
import PageTitle from '../components/PageTitle'
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import Loading from '../components/Loading'
import Navbar from '../components/Navbar';
import Modal from '../components/Modal'

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const context = useContext(DataContext);
    const { footer, contactInfo } = context.allData
    const { handleSearch, handleOffcanvas } = context;
    
    if(!contactInfo) {
        return <Loading />
    }
    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} />
            <PageTitle pageName="Contact Us" />
            

            <section className="contact pt-120 pb-120">
            <Modal></Modal><br/><br/>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="contact-form-wrap">
                            <iframe src="https://koalendar.com/e/schedule-a-call-with-rapidsite?embed=true" width="100%" height="800px" frameborder="0"></iframe>
                                <div className="form-response"></div>
                            </div>
                        </div>
                
                        <div className="col-lg-5">
                        {/* <div class='contactform'><Modal></Modal></div> */}
                            
                        </div>
                    </div>
                </div>
            </section>
            
            <Footer data={footer} />
        </>
    )
}

export default Contact
