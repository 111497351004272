import React, { useState } from "react";
import { createClient } from '@supabase/supabase-js'

const supabase = createClient('https://brqaxjmymjjndkxbivgd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJycWF4am15bWpqbmRreGJpdmdkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODIyNjc4MzcsImV4cCI6MTk5Nzg0MzgzN30.2HgSpUxTn2oOeDWEYALCEQsruIG4W9w5czjbhDuGehU')

const FORM_ENDPOINT = ""; // TODO - fill on the later step


const Modal = () => {
    const [formState, setFormState] = useState({
      name: "",
      email: "",
      message: "",
    });
  
    const [submitted, setSubmitted] = useState(false);
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const { name, email, message } = formState;
      const { data, error } = await supabase
        .from('contactform')
        .insert([{ name, email, message }]);
      if (error) {
        console.log('Error inserting data:', error);
      } else {
        setSubmitted(true);
      }
    };
  
    if (submitted) {
      return (
        <>
          <center><div className="text-2xl">Your message has been sent!</div>
          <div className="text-md">Be on the lookout for an email/text from us.</div></center>
        </>
      );
    }
  
    return (
      <center><br/><br/><br/><form
        onSubmit={handleSubmit}
        method="POST"
        target="_blank"
        className="max-w-lg"
      >
        <h2 class='black'>Contact Us</h2> <br/>
        <div className="mb-3 pt-0 px-5">
          <input
            type="text"
            placeholder="Your name"
            name="name"
            value={formState.name}
            onChange={handleInputChange}
            className="px-3 py-3 padding-placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
            required
          />
        </div>
        <div className="mb-3 pt-0 px-5">
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formState.email}
            onChange={handleInputChange}
            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
            required
          />
        </div>
        <div className="mb-3 pt-0 px-5">
    <textarea
      placeholder="Your message"
      name="message"
      value={formState.message}
      onChange={handleInputChange}
      className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
      required
    />
  </div>
  <div className="mb-3 pt-0">
    <button
      className="bg-blue-500 text-black active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="submit"
    >
      Send a message
    </button>
  </div>
</form> </center>
    )}

export default Modal;