import React, { Component, useState } from 'react';

// Logo
import Logo from '../images/logo.png';
import stickyLogo from '../images/sticky-logo.png';

import { Link, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaSearch, FaGlobe, FaAngleDown, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';

//import image
import Flag1 from '../images/flag1.png'
import Flag2 from '../images/flag2.png'
import Flag3 from '../images/flag3.png'
import Flag4 from '../images/flag4.png'
import Flag5 from '../images/flag5.png'
import Flag6 from '../images/flag6.png'

class Navbar extends Component {
    _isMounted = false;

    state = {
        stickyClass: '',
        mobileNav: false
    }
    myRef = React.createRef();

    

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;
        // window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {
        
        let lastScrollY = window.scrollY

        if (lastScrollY >= 70 && this._isMounted) {
            this.setState((state, props) => ({
                stickyClass: 'sticky fadeInDown animated fixed-top'
            }));
        } else {
            if(this._isMounted)
            this.setState((state, props) => ({
                stickyClass: ''
            }));
        }
    }

    funRef(e) {
        e.preventDefault();
        this.setState({
            mobileNav: !this.state.mobileNav
        })
    }

    style = {
        color: "#5551EF"
    }
    
    render() {
        let headerTop = this.props.headerTop
        return (
            
            <header className={`header ${headerTop ? "" : "fixed-top"}`}>
                <div className={`header-top pb-2 pb-lg-0 ${headerTop ? "" : "d-none"}`}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-9 text-center text-lg-left">
                                <div className="info-bar text-white">
                                    
                                </div>
                            </div>
                            <div className="col-lg-3 text-center text-lg-right">
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${this.state.stickyClass} ${headerTop ? "bg-white" : ""} header-main`}>
                    <Container>
                        <Row className="position-relative">
                            <Col lg={2} xs={5}>
                                {/* Logo */}
                                <div className="logo">
                                    <Link to="/">
                                        <img src={headerTop ? stickyLogo : Logo} className="main-logo" alt="Logo"/>
                                        <img src={stickyLogo} className="sticky-logo" alt="Sticky Logo"/>
                                    </Link>
                                </div>
                                {/* End Logo */}
                            </Col>

                            <Col lg={10} xs={7} className="d-flex align-items-center justify-content-end position-static">
                                {/* Nav Wrapper */}
                                <div className="nav-wrapper">
                                    {/* Menu Button */}
                                    <div id="menu-button" onClick={(e) => this.funRef(e)}>
                                        <span></span>
                                    </div>
                                    {/* End Menu Button */}
                                    <div className={`nav-wrap-inner ${this.state.mobileNav ? 'active' : ''}`} ref={this.myRef}>
                                        {/* Nav */}
                                        <ul className="nav justify-content-end">
                                            <li><NavLink to="/">Home</NavLink>
                                            </li>
                                            <li><NavLink to="/faq">FAQ</NavLink></li>
                                            <li><NavLink to="/pricing">Pricing</NavLink></li>
                                            <li><NavLink to="/contact">Contact</NavLink></li>
                                            <li><NavLink to="#">Examples</NavLink>
                                                <ul className="sub-menu">
                                                    <li><NavLink to="#">Contractors</NavLink>
                                                    <ul className="sub-menu">
                                                        <li><a href="https://electrical.rapidsite.us" activeStyle={this.style}>Electrician</a></li>
                                                        <li><a href="https://plumbing.rapidsite.us" activeStyle={this.style}>Plumber</a></li>
                                                        <li><a href="https://roofing.rapidsite.us" activeStyle={this.style}>Roofing</a></li>
                                                    </ul>
                                                    </li>
                                                    
                                                    <li><a href="https://food.rapidsite.us" activeStyle={this.style}>Restaurant</a></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to="/coming-soon" >Client Login</NavLink></li>
                                            
                                        </ul>
                                        {/* End Nav */}
                                        

                            
                            
                                    </div>
                                </div>
                                {/* End Nav Wrapper */}


                                <div className="d-flex align-items-center">
                                    

                                    

                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
        )
    }
}


export default Navbar

