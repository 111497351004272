import React, { useContext, useState, useEffect } from 'react'
import { DataContext } from '../context'
import Banner from '../components/Banner'
import Feature from '../components/Feature';
import Solution from '../components/Solution';
import Service from '../components/Service';
import Price from '../components/Price';
import Price2 from '../components/Examples';
import Team from '../components/Team';
import Testimonial from '../components/Testimonial';
import HomeBlog from '../components/HomeBlog';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Counter from '../components/Counter';
import Logos from '../components/LogoCarousel';


function Home() {
      
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const context = useContext(DataContext);
    const { hero1, companyLogo, feature, solution, service, counter, price, price2, team, testimonial, blog, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;
    
    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}}/>
            <Banner data={hero1} />
            <Logos data={companyLogo} />  
            <Service data={service} />
            
            <Counter data={counter} />
            <Solution data={solution} />
            <Price data={price} />         
            <Feature data={feature} />
            <Footer data={footer} />
        </>
    )
}

export default Home
