import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaCalendarAlt,
} from "react-icons/fa";
import Loading from "./Loading";
import { Container, Row, Col } from "react-bootstrap";

function Footer({ data, styleTwo, styleThree }) {
  // let publicUrl = process.env.PUBLIC_URL + '/'
  if (!data) {
    return <Loading />;
  }
  const {
    widgetAddress,
    widgetServices,
    widgetSolutions,
    widgetRecentPost,
    socials,
    copyrightText,
  } = data;

  return (
    <footer
      className={`footer ${
        styleTwo ? "style--two" : styleThree ? "style--three" : ""
      }`}
    >
      <div className="footer-bg-shape"></div>

      <center><div className="footer-top">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="widget widget_contact">
                <h3 className="widget-title">{widgetAddress.title}</h3>
                <ul>
                  <li>
                    <span className="icon">
                      
                    </span>
                    <a href={`mailto:${widgetAddress.mail.link}`}>
                      {widgetAddress.mail.text}
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      
                    </span>
                    <a href={`callto:${widgetAddress.phone.link}`}>
                      {widgetAddress.phone.text}
                    </a>
                  </li>
                  <li>
                    <span className="icon">
                      
                    </span>
                    {widgetAddress.location}
                  </li>
                </ul>

                
              </div>
            </Col>
            
          </Row>
        </Container>
      </div></center>

      <div className="footer-bottom">
        <Container>
          <Row>
            <Col>
              <div className="footer-bottom-text text-center">
                Copyright © {new Date().getFullYear()}{" "}
                <a href={copyrightText.link} className="bold">
                  {copyrightText.linkText}
                </a>{" "}
                {copyrightText.lastText}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
