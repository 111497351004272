import React, { useContext, useEffect } from 'react'
import { DataContext } from '../context'
import PageTitle from '../components/PageTitle'
import Service from '../components/Service';
import Price2 from '../components/Price2';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Examples from '../components/Examples';
import Packagetable from '../components/Packagetable';
import Solution from '../components/Solution';


function Services() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const context = useContext(DataContext);
    const { service, price2, testimonial, footer, examples, packagetable, title, solution, } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} />
            <PageTitle pageName="Simple and Transparent Pricing" />
            <Price2 data={price2} />
            <Packagetable data={packagetable} />
            <Solution data={solution} />
            <Footer data={footer} />
        </>
    )
}

export default Services
