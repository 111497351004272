import React, { useContext, useState, useEffect } from 'react'
import { DataContext } from '../context'
import PageTitle from '../components/PageTitle'
import { FaPlay } from 'react-icons/fa';
import Loading from '../components/Loading';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../components/Footer';
import ModalVideo from 'react-modal-video'
import Accordion from "react-bootstrap/Accordion";
import Counter from '../components/Counter';
import Price from '../components/Price';
import Navbar from '../components/Navbar';

function SingleService() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const context = useContext(DataContext);
    const { serviceDetails, counter, price, footer } = context.allData
    const { handleSearch, handleOffcanvas } = context;

    const [isOpen, setOpen] = useState(false)

    if(!serviceDetails) {
        return <Loading />
    }
    return (
        <>
            <Navbar handler={{handleSearch, handleOffcanvas}} />
            <PageTitle pageName="FAQ" />
            <section className="service-details pt-120 pb-120">
                <Container>
                    
                    <Row>
                        <Col lg={12}>
                            <div className="content pb-2">
                                <h2>{serviceDetails.howItWorks.title}</h2>
                                <p>{serviceDetails.howItWorks.desc}</p>
                            </div>


                            <Accordion className="mb-4" defaultActiveKey="0">
                                <div>
                                    <Accordion.Toggle variant="link" eventKey="0">
                                        <h3>{serviceDetails.accordion.one.title}</h3>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <p>{serviceDetails.accordion.one.desc}</p>
                                    </Accordion.Collapse>
                                </div>

                                <div>
                                    <Accordion.Toggle variant="link" eventKey="1">
                                        <h3>{serviceDetails.accordion.two.title}</h3>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <p>{serviceDetails.accordion.two.desc}</p>
                                    </Accordion.Collapse>
                                </div>

                                <div>
                                    <Accordion.Toggle variant="link" eventKey="2">
                                        <h3>{serviceDetails.accordion.three.title}</h3>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <p>{serviceDetails.accordion.three.desc}</p>
                                    </Accordion.Collapse>
                                </div>

                                <div>
                                    <Accordion.Toggle variant="link" eventKey="3">
                                        <h3>{serviceDetails.accordion.four.title}</h3>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <p>{serviceDetails.accordion.four.desc}</p>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>


                            

                        </Col>
                    </Row>
                </Container>
            </section>
            <Counter data={counter} />
            {/* <Price data={price} classText="pt-120 pb-90" styleFour="true" /> */}
            <Footer data={footer} />
        </>
    )
}

export default SingleService
